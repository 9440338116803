import React from "react";
import StandardSectionHeader from "./StandardSectionHeader";

const LongContent = ({ title, header, description, content }) => {
  return (
    <section className="bg-gradient-to-b from-gray-100 to-white border-t border-gray-200">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-10 md:py-10">
          {/* Section header */}
          <StandardSectionHeader
            title={title}
            header={header}
            description={description}
          />

          <div className="relative px-4 sm:px-6 lg:px-8">
            <div className="text-lg max-w-prose mx-auto">{content}</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LongContent;
